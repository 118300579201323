import React from 'react'
import Layout from './../../components/help/Layout/Layout'
import Seo from './../../components/help/Seo'
import Header from '../../components/ui/Header/Header'
import HeaderFixed from './../../components/ui/HeaderFixed/HeaderFixed'
import Intro from '../../components/ui/Intro/Intro'
import Development from '../../components/pages/About/Development/Development'
import Competencies from '../../components/pages/About/Competencies/Competencies'
import Slogan from '../../components/pages/About/Slogan/Slogan'
import CompanyNumbers from '../../components/pages/About/CompanyNumbers/CompanyNumbers'
import Awards from '../../components/pages/About/Awards/Awards'
import PageNavigation from '../../components/ui/PageNavigation/PageNavigation'
import { getHrefToHome } from '../../utils/getHrefToHome'
import { useStaticQuery, graphql } from 'gatsby'

const AboutPage = () => {
    const lang = 'hy'
    const hrefHome = getHrefToHome(lang)

    const data = useStaticQuery(graphql`
        {
            seo: datoCmsRenshinSeo(pageName: { eq: "About company" }, locale: { eq: "hy" }) {
                titleH1
                crumbsName
                bgDesktop {
                    url
                }
                bgTabletA {
                    url
                }
                bgTabletP {
                    url
                }

                bgMobile {
                    url
                }

                footerLinks {
                    title
                    url
                }
                seo {
                    title
                    description
                }
            }

            main: datoCmsRenshinSeo(pageName: { eq: "Main" }, locale: { eq: "hy" }) {
                crumbsName
            }
        }
    `)

    const photo = {
        1920: data.seo.bgDesktop.url,
        1440: data.seo.bgDesktop.url,
        1024: data.seo.bgTabletA?.url,
        768: data.seo.bgTabletP?.url,
        360: data.seo.bgMobile?.url
    }

    const breadCrumbs = [
        { name: `${data.main.crumbsName}`, href: `${hrefHome}`, current: false },
        { name: `${data.seo.crumbsName}`, current: true }
    ]

    return (
        <Layout lang={lang}>
            <Seo title={data.seo?.seo?.title} description={data.seo?.seo?.description} />
            <Header light lang={lang} />
            <HeaderFixed lang={lang} light />
            <Intro crumbs={breadCrumbs} title={`${data.seo.titleH1}`} photo={photo} lang={lang} />
            <Development lang={lang} />
            <Competencies lang={lang} />
            <Slogan lang={lang} />
            <CompanyNumbers lang={lang} />
            <Awards lang={lang} />
            <PageNavigation links={data.seo.footerLinks} lang={lang} />
        </Layout>
    )
}

export default AboutPage
